import React from 'react'
import vector from '../../assets/notext/vector.png'

const NoText = () => {
  return (
      <section class="w-full bg-white rounded-bl-[120px]">
      <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
        <img class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src={vector}></img>
        <div class="text-center lg:w-2/3 w-full">
          <h1 class="text-2xl mb-4 font-bold tracking-tight text-black sm:text-3xl md:text-4xl">Visit Your Dashboard</h1>
          <p class="mb-8 leading-relaxed text-base text-justify-center text-gray-500">Your dashboard is broken down into 3 sections.</p>
        </div>
      </div>
    </section>
  )
}

export default NoText