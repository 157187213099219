/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import NormalHeader from '../normalHeader/NormalHeader'

const BlogSection = ({posts}) => {
  return (
    <section class = "w-full bg-white">
        <main>
            <NormalHeader
                head1="ARTICLES AND BLOGS"
                mainHeading="Go through our expansive library of wisdom"
                sub="Thousands of articles on all things."
            />
            <section className="py-6 sm:py-12  bg-white">
	<div className="container p-6 mx-auto space-y-8 items-center ...">

		<div className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2 lg:grid-cols-3">

        <article className="flex flex-col .bg-white">
				<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum">
					<img alt="" className="object-cover w-full h-52 dark:bg-gray-500" src="https://source.unsplash.com/200x200/?fashion?2" />
				</a>
				<div className="flex flex-col flex-1 pt-6">
					<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum"></a>
					<a rel="noopener noreferrer" href="#" className="text-xl tracking-wider  hover:underline text-black text-left">13 Online Will Makers Compared: Which is Best for You?</a>


				</div>
			</article>
			<article className="flex flex-col .bg-white">
				<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum">
					<img alt="" className="object-cover w-full h-52 dark:bg-gray-500" src="https://source.unsplash.com/200x200/?fashion?3" />
				</a>
				<div className="flex flex-col flex-1 pt-6">
					<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum"></a>
					<a rel="noopener noreferrer" href="#" className="text-xl tracking-wider hover:underline text-black text-left">13 Online Will Makers Compared: Which is Best for You?</a>


				</div>
			</article>
			<article className="flex flex-col .bg-white">
				<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum">
					<img alt="" className="object-cover w-full h-52 dark:bg-gray-500" src="https://source.unsplash.com/200x200/?fashion?4" />
				</a>
				<div className="flex flex-col flex-1 pt-6">
					<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum"></a>
					<a rel="noopener noreferrer" href="#" className="text-xl tracking-wider  hover:underline text-black text-left">13 Online Will Makers Compared: Which is Best for You?</a>


				</div>
			</article>
		</div>
        <div className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2 lg:grid-cols-3">
			<article className="flex flex-col .bg-white">
				<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum">
					<img alt="" className="object-cover w-full h-52 dark:bg-gray-500" src="https://source.unsplash.com/200x200/?fashion?1" />
				</a>
				<div className="flex flex-col flex-1 pt-6">
					<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum"></a>
					<a rel="noopener noreferrer" href="#" className="text-xl tracking-wider  hover:underline text-black text-left">13 Online Will Makers Compared: Which is Best for You?</a>


				</div>
			</article>
			<article className="flex flex-col .bg-white">
				<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum">
					<img alt="" className="object-cover w-full h-52 dark:bg-gray-500" src="https://source.unsplash.com/200x200/?fashion?2" />
				</a>
				<div className="flex flex-col flex-1 pt-6">
					<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum"></a>
					<a rel="noopener noreferrer" href="#" className="text-xl tracking-wider  hover:underline text-black text-left">13 Online Will Makers Compared: Which is Best for You?</a>


				</div>
			</article>
			<article className="flex flex-col .bg-white">
				<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum">
					<img alt="" className="object-cover w-full h-52 dark:bg-gray-500" src="https://source.unsplash.com/200x200/?fashion?3" />
				</a>
				<div className="flex flex-col flex-1 pt-6">
					<a rel="noopener noreferrer" href="#" aria-label="Te nulla oportere reprimique his dolorum"></a>
					<a rel="noopener noreferrer" href="#" className="text-xl tracking-wider  hover:underline text-black text-left">13 Online Will Makers Compared: Which is Best for You?</a>


				</div>
			</article>

		</div>
	</div>
</section>
        </main>
    </section>
  )
}

export default BlogSection