import React from 'react'

const DoMathItem = (props) => {
  return (
        <div class="p-4 md:w-1/2 flex flex-col text-center items-center">
            <div class = "flex justify-center align-items-center">
                <div class="w-16 h-16  flex justify-center align-items-center rounded-full bg-[#171A46] mb-5 flex-shrink-0">
                    <img class = "h-16 w-16" src = {props.icon} alt = "vector" />
                    <h1 class = "ml-4 text-2xl mb-8 font-bold tracking-tight text-white sm:text-3xl md:text-4xl">{props.percentage}</h1>
                </div>
                
            </div>
            <div class=" w-90">
                <p class="mt-3 text-base text-gray-300 sm:mx-auto sm:mt-5 sm:max-w-xl sm: md:mt-5 md:text-base lg:mx-0 test-justify-left">{props.content}</p>
            </div>
        </div>
  )
}

export default DoMathItem