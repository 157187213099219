/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import homeVector from "../../assets/homeVector/homeVector.svg";

const hero = () => {
  return (
    <section class="w-full bg-[#171A46] rounded-br-[120px]">
      <main class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center ">
        <div class="sm:pt-5 lg:text-left pt-2 pb-20 ">
          <h1 class="text-2xl mb-8 font-bold tracking-tight text-white sm:text-3xl md:text-4xl">
            <span class="block">Be Prepared</span>
          </h1>
          <p class="mt-3 text-base text-gray-300 sm:mx-auto sm:mt-5 sm:max-w-xl sm: md:mt-5 md:text-base lg:mx-0 test-justify-left">
            Let us help you and your family plan for challenging times and bring order to the chaos that is everyday life.
          </p>
          <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <button class="bg-white  text-[#171A46] font-semibold py-3 px-12 rounded-full">
              Get Started
            </button>
          </div>
          <img
            src={homeVector}
            alt="homeVector"
            class="absolute right-0 h-auto lg:w-[75%] lg:-bottom-[1%] md:w-[65%] md:-bottom-[-10%] xl:w-[80%] xl:-bottom-[7%] sm:-bottom-[0] sm:w-[80%] 2xl:w-[65%] 2xl:-bottom-[5%] "
          />
        </div>
      </main>
    </section>
  );
}

export default hero
