import React, {useLayoutEffect}from 'react'
import DoMath from '../components/home/DoMath'
import Hero from '../components/home/Hero'
import WhyEntrustus from '../components/home/WhyEntrustus'
import AboutYourself from '../components/home/AboutYourself'
import Nominee from '../components/home/Nominee'
import ToDoList from '../components/home/ToDoList'
import NoText from '../components/home/NoText'
import HowToGetStarted from '../components/home/HowToGetStarted'
import BlogSection from '../components/blog/BlogSection'
import {Link} from 'react-router-dom'
const Home = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  
  return (
    <div class = "bg-[#171A46]">
          <div class = "bg-white">
            <Hero/>
          <WhyEntrustus/>
          </div>
          <div class = "bg-white">
          <DoMath/>
          </div>
          <div class = "bg-[#171A46]">
          <HowToGetStarted/>
          </div>
          <AboutYourself/>
          <div class = "bg-[#171A46]">
          <NoText/>
          <ToDoList/>
          <Nominee/>
          {/* <BlogSection/> */}
          </div>
    </div>

  )

}

export default Home