import React from 'react'
import GetStarted from '../../assets/GetStarted/getStarted.png'

const HowToGetStarted = () => {
  return (
        <section class="w-full bg-white flex-col justify-center items-center rounded-tr-[120px] ">
            <div class="container  pt-12 px-5 py-12 mx-auto">
                <div class="flex flex-col text-center w-full ">
                    <h1 class="text-2xl mb-2 font-bold tracking-tight text-black sm:text-3xl md:text-4xl">How to get started</h1>
                    <p class="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-500  sm:mt-5 sm:max-w-xl sm:text-base md:mt-5 md:text-base  text-justify-center">Learn How EntrustUs Works</p>
                </div> 
            </div>
            <div class="container mx-auto  flex  px-5 md:flex-row flex-col items-center pb-16">
                <div class="lg:max-w-lg lg:w-1/2 md:w-1/2 w-5/6 mb-10 md:mb-0">
                    <img class=" object-center object-scale-down rounded" alt="hero" src={GetStarted}/>
                </div>
            <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center ">
                <h1 class="text-2xl mb-4 font-bold tracking-tight text-black sm:text-3xl md:text-4xl">Sign Up</h1>
                <p class="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm: md:mt-5 md:text-base lg:mx-0 test-justify-left">Our secure end-of-life planning platform guides you to create or upload documents like living wills, advance directive forms, and estate planning documents that ensure users create a plan for a meaningful ending so they can live their best life.</p>
            </div>
            </div>
        </section>
  )
}

export default HowToGetStarted