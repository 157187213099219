import React,{useLayoutEffect} from 'react'

const Resources = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  
  return (
    <div>Resources</div>
  )
}

export default Resources